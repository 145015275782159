import React from 'react';
import Loadable from 'react-loadable';
import { LoadingPage } from '@/components/loading/loadingBase';
import PrivateProvider from '@/components/providers/PrivateProvider';
import HeaderMetadata from '@/layouts/HeaderMetadata';
const SetupManagerLoadable = Loadable({
  loader: () => import('@/sections/SetupManager'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});
const SetupManagerPage: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderMetadata title="Setup Manager" />
      <PrivateProvider>
        <SetupManagerLoadable />
      </PrivateProvider>
    </React.Fragment>
  );
};

export default SetupManagerPage;
